import React, { useEffect, useState } from "react";
import "./carnival-details.css";
import minus from './image/minus.png';

export default function CarnivalPassTnC(props) {
    const [isContentVisible, setIsContentVisible] = useState(false);
    const handleDescriptionClick = () => {
        setIsContentVisible(!isContentVisible);
    };

    return (
        <div className="carnival-about-us-page-content" >
            <div className="carnival--about--us--About">
                <h2>
                    {!isContentVisible && <span onClick={handleDescriptionClick}>+</span>}
                    {isContentVisible && <span onClick={handleDescriptionClick}><img src={minus}></img></span>}
                    Terms & Conditions
                </h2>
                {isContentVisible && (
                    <div>
                        <ol type='a'>
                            <li><b>No refunds:</b> All ticket sales are final. No refunds will be issued, even in case of rescheduling.</li>
                            <li><b>Security:</b> Security procedures, including frisking, will be strictly enforced.</li>
                            <li><b>Prohibited Items:</b> For the safety of all attendees, please refrain from bringing any dangerous or potentially hazardous objects, such as weapons, knives, firearms, fireworks, helmets, laser devices, bottles, or musical instruments. Possession of such items may result in immediate ejection from the venue.</li>
                            <li><b>Liability Waiver:</b> The sponsors, performers, and organizers are not liable for any injuries or damages that may occur during the event. Any disputes will be subject to the jurisdiction of Uttar Pradesh courts.</li>
                            <li><b>Intoxication:</b> Individuals who appear to be intoxicated may be denied entry.</li>
                            <li><b>Late Entry:</b> Organizers reserve the right to deny late entry to the event.</li>
                            <li><b>Venue Rules:</b> Please adhere to all venue rules and regulations.</li>
                        </ol>
                        {/**
                                            <div dangerouslySetInnerHTML={{ __html: pass.tc }}></div>
                                             */}
                    </div>
                )}
            </div>

        </div>

    );
}
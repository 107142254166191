import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import "./carnival-details.css";

import CarnivalPassBookingInput from "./CarnivalPassBookingInput";
import Commons from "../Commons";
import Loginpopup from "../Navbar/Loginmodal";

export function CarnivalPassBookButton(props) {
    const pass = props.pass;

    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [state1, setState1] = useState({ bottom: false });
    const toggleDrawer1 = (anchor, open) => {
        if (Commons.getUserData()) {
            // console.log('Anchor: ', anchor, open);
            setState1({ ...state1, [anchor]: open });
        } else {
            setShowLoginPopup(true);
        }
    }


    return (
        <>
            {showLoginPopup && <Loginpopup />}
            {["bottom"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <div className='carnival--about--us--details2' >
                        <button
                            onClick={
                                async (event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    toggleDrawer1(anchor, true);
                                }
                            }
                        > BOOK NOW</button>
                    </div>
                    <Drawer
                        anchor={anchor}
                        open={state1[anchor]}
                        onClose={() => toggleDrawer1(anchor, false)}
                        PaperProps={{
                            sx: {
                                backgroundColor: "black",
                                color: "white",
                                // top: '20px',
                                margin: "5px", // Adjust the margin values as needed
                                borderTopLeftRadius: "8px", // Set border-top-left-radius
                                borderTopRightRadius: "8px", // Set border-top-right-radius
                            },
                        }}
                    >

                        <div
                            style={{ textAlign: 'right', }}>
                            <button
                                onClick={
                                    (event) => {
                                        toggleDrawer1(anchor, false);
                                        // alert('Close Booking Dialog');
                                    }
                                }
                            >X</button>
                        </div>
                        <div style={{
                            // maxHeight: '100%',
                            overflow: 'auto'
                        }}>
                            <CarnivalPassBookingInput pass={pass} />
                        </div>
                    </Drawer>
                </React.Fragment>
            ))}
        </>
    );
}

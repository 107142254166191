import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Carnival.css";

import Box from "@mui/material/Box";
import Group from "./image/Group.png";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Modal from "@mui/material/Modal";
import Slider from "@mui/material/Slider";
import { GrCaretNext } from "react-icons/gr";
import GPIcon from "./image/GPIcon.png";
import cross1 from "./image/cross1.svg";
import search from "./image/search.svg";

import { Carousel } from "antd";
import axios from "axios";
import { LuSearch } from "react-icons/lu";
import { Link } from "react-router-dom";
import Loginpopup from "../Navbar/Loginmodal";

import CarnivalPassCard from "./CarnivalPassCard";
import CarnivalSpecialPassCard from "./CarnivalPassCard";

import Commons from "../Commons";
import { CarnivalAPI } from "./CarnivalAPIs";
import ClubHeader from "../Club-aboutus/ClubHeader";

const searchBarStyleMobile = {
  height: "40px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#120829",
  // borderRadius: "20px",
  padding: "10px 10px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.3s ease-in-out",
  color: "white",
  border: "1px solid grey",
};

const inputStyle = {
  border: "none",
  outline: "none",
  fontSize: "12px",
  width: "100%",
  backgroundColor: "#120829",
  color: "white",
};

export default function ClubCarnival() {
  const { type, club_id } = useParams();
  // console.log('Offer Type', offer_type, club_id);

  const [passes, setPasses] = useState([]);

  // Fetch all Carnival Pass of a Type
  useEffect(() => {
    const url = `Api/getData/allCarnivalPassOfType?type=${type}${club_id ? `&club_id=${club_id}` : ''}`;
    Commons.doFetch(url)
      .then((response) => {
        // console.log('All Carnival: ', url, response);

        if (response.status === "true") {
          setPasses([]);
        } else {
          let passes = response.data || [];
          passes = passes.map(p => {
            p = { ...p, ...p.passDetails };
            return p;
          });
          passes = removeDuplicates(passes, []);

          setPasses(passes);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const removeDuplicates = (passes, specials) => {
    // remove duplicates
    passes.forEach((p, index) => {
      const passID = p.id;
      const found = specials?.filter(sp => {
        return sp.id === passID;
      });
      if (found?.length > 0) {
        passes.splice(index, 1);
      }
    });
    return passes;
  }

  // banners
  const [banners, setBanners] = useState([]);
  var url = Commons.URL.Upload;

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/getCarnivalBanner")
      .then((response) => setBanners(response.data.data))
      .catch((error) => console.log(error));
  }, []);

  // filtered passes
  const [filteredItems, setFilteredItems] = useState([]);
  const [userInput, setUserInput] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setUserInput(inputValue);

    // Fetch options based on the inputValue
    const filteredData = getOptions(inputValue, passes);
    setFilteredItems(filteredData);

  };

  const getOptions = (inputValue, passes) => {
    inputValue = inputValue?.trim()?.toLowerCase();
    if (!inputValue) {
      return []; // Return empty array if input is empty or whitespace
    }

    const filteredData = passes.filter(
      (pass) => {
        let match = pass.name.toLowerCase().includes(inputValue);

        if (!match) {
          match = pass.club?.name.toLowerCase().includes(inputValue);
        }

        if (!match) {
          const PD = pass.passDetails;
          for (let alcType of CarnivalAPI.Alcohols) {
            const alcohols = PD ? PD[alcType] : undefined;
            if (alcohols) {
              match = alcohols.toLowerCase().includes(inputValue);
              if (match) {
                break;
              }
            }
          }
        }


        // console.log('Match Option: ', match, inputValue, option);
        return match;
      }
    );

    return filteredData.length > 0 ? filteredData : []; // Return filteredData if it has items else return empty array
  };

  // contents
  const buildBanner = () => {
    return (
      <div className="new--year--banner--5" >
        <div className="Artist-viewall-banner">
          <Carousel autoplay>
            {banners.map((e) => (
              <div className="Artist-viewall-banner--img">
                <img src={url + e.img} alt="img" />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    );
  }

  const buildPasses = () => {
    return (
      <div
        className="new--year--special--package"
      >
        {userInput.trim() === "" ? (
          passes.map((e) => ( // Carnival Pass Card
            <CarnivalPassCard pass={e} hideSimilar={true} />
          ))
        ) :
          (
            filteredItems?.map((e) => (// Carnival Pass Card
              <CarnivalPassCard pass={e} hideSimilar={true} />
            ))
          )
        }
      </div>

    );
  }

  const buildSearch = () => {
    return (
      <div style={searchBarStyleMobile}>
        <input
          style={inputStyle}
          type="text"
          placeholder="Search..."
          value={userInput}
          onChange={handleInputChange}
        />
        <span>
          <LuSearch />
        </span>
      </div>
    );
  }

  return (
    <div className="New--year--page--design--1" style={{ position: 'relative', top: '75px' }}>
      <div className="new--year--mobile--view--1">
        <ClubHeader id={club_id} />

        <div>
          {buildSearch()}
          {buildPasses()}
        </div>

        {buildBanner()}
      </div>
    </div>
  );

}

import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import Commons from "./../Commons";
import "./Carnival.css";
import soldout from './image/oops_sold.jpg';

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Modal from "@mui/material/Modal";
import carrotIcon from "../Assets/carret.png";
import group1 from "../Assets/Group 12053.svg";
import group from "../Assets/Group.svg";
import vector from "../Assets/Vector.svg";
import vector1 from "../Assets/Vector1.svg";
import arrowIcon from "./image/arrow1.svg";
import couponIcon from "./image/coupon.png";
import './Coupons.css';
import { CarnivalAPI } from "./CarnivalAPIs";
import ClubCard from "./ClubCard";
import CarnivalPassTnC from "./CarnivalPassTnC";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: '345px',
    // height: 400,
    bgcolor: "#120829",
    // border: '2px solid #000',
    boxShadow: 24,
    //   p: 4,
};

const PassType = CarnivalAPI.PassType;

const user = Commons.getUserData();
const CDC = Commons.Format.Currency.decimalCount;
const pay_form_id = 'pay_form_id' + Math.random();

const loadUserPassDetails = CarnivalAPI.loadUserPassDetails;

function CarnivaPassGuestList(props) {

    return (
        <>
        </>
    );
}

function CarnivaPassCoupons(props) {
    let pass = props.pass;
    const setSelected = props.setSelected;
    // console.log('Coupons for Pass: ', userPassDetails);

    const [selectedCoupon, setSelectedCouponState] = useState(null);
    let availableCoupons = pass?.AvailableCoupons || [];
    // console.log('available Coupons: ', grandTotal, availableCoupons, selectedCoupon);

    const [activeTab, setActiveTab] = useState("coupons");
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openedCoupon, setOpenedCoupon] = useState(null);
    const [imageSrcs, setImageSrcs] = useState({ vector });

    const setSelectedCoupon = (coupon) => {
        setSelectedCouponState(coupon);
        if (setSelected) {
            setSelected(coupon);
        }
    }

    const handleToggleDescription = (couponCode) => {
        setOpenedCoupon(openedCoupon === couponCode ? null : couponCode);
        // setImageSrc(openedCoupon === couponCode ? vector : vector1);
        if (!imageSrcs[couponCode]) {
            // Set the image source for the clicked coupon code
            setImageSrcs({
                ...imageSrcs,
                [couponCode]: vector1, // Replace 'new_image_url' with your desired image URL
            });
        } else {
            // Remove the image source for the clicked coupon code
            const updatedImageSrcs = { ...imageSrcs };
            delete updatedImageSrcs[couponCode];
            setImageSrcs(updatedImageSrcs);
        }
    };

    return (
        <>
            <div className="new--year--order--cart">
                <div className="new--year--your--order--1">
                    <img src={couponIcon}></img>
                    <p className="New--year--page--add--more">Use Coupon</p>
                </div>
                <div
                    className="new--year--your--order"
                    onClick={handleOpen}
                >
                    <img src={arrowIcon}></img>
                </div>
            </div>
            <Modal
                // className="mobile--view--coupons"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="coupons--header">
                        <p className="m-0">Coupons</p>
                        <p className=" m-0" onClick={handleClose}>
                            <img src={group1}></img>
                        </p>
                    </div>

                    <div className="form-group" style={{ backgroundColor: 'white' }} >
                        <div className="coupons--list p-2">
                            <div className="coupons--tabs--card">
                                <p
                                    className={`partywity--coupons ${activeTab === "coupons" ? "active-tab" : ""}`}
                                    onClick={() => handleTabClick("coupons")}
                                >
                                    PW Coupons
                                </p>
                                {/*
                                <p
                                    className={`partywity--coupons ${activeTab === "voucher" ? "active-tab" : ""
                                        }`}
                                    onClick={() => handleTabClick("voucher")}
                                >
                                    My Vouchers
                                </p>
                                */}
                            </div>

                            {activeTab === "coupons" && (
                                <>
                                    {availableCoupons.map((coupon) => (
                                        <div className="coupons--code--design">
                                            <div
                                                className={`coupons--card mb-2 ${selectedCoupon === coupon.code
                                                    ? "selected-button"
                                                    : ""
                                                    }`}
                                                key={coupon.id}
                                                value={coupon.code}
                                            >
                                                <div className="coupons--headericon">
                                                    <div className="coupon--header--icon">
                                                        <img src={coupon.img ? (Commons.URL.Upload + coupon.img) : group}></img>
                                                    </div>
                                                    <div className="dis--card--coupons">
                                                        <div className="off--discount--text">
                                                            {coupon.discount_type === "amount" ? (
                                                                <h5 className="m-0">
                                                                    UP TO {coupon.discount} OFF
                                                                </h5>
                                                            ) : (
                                                                <h5 className="m-0">
                                                                    {coupon.discount}% OFF UP TO{" "}
                                                                    {coupon.capping}
                                                                </h5>
                                                            )}

                                                            <p className="m-0">
                                                                Save {coupon.capping} with this code
                                                            </p>
                                                            <div className="c--code">
                                                                <h3>{coupon.code}</h3>
                                                            </div>
                                                        </div>
                                                        <div className="dropdown--terms">
                                                            <div
                                                                className="terms--condition"
                                                                onClick={() =>
                                                                    handleToggleDescription(coupon.code)
                                                                }
                                                            >
                                                                View Details &nbsp;
                                                                <img src={imageSrcs[coupon.code] || vector} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {openedCoupon === coupon.code && (
                                                    <div className="term--discription">
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: coupon.terms_conditions,
                                                            }}
                                                        ></p>
                                                    </div>
                                                )}

                                                {coupon.can_apply ? (
                                                    <button
                                                        id={`select-coupon-${coupon.id}`}
                                                        onClick={() => {
                                                            if (selectedCoupon === coupon.code) {
                                                                setSelectedCoupon(null); // Unselect if already selected
                                                            } else {
                                                                setSelectedCoupon(coupon.code); // Select if not selected
                                                            }
                                                        }}
                                                        className={`coupon-button ${(selectedCoupon === coupon.code) ? "selected-button" : ""}`}
                                                    >
                                                        Apply
                                                    </button>
                                                ) : (
                                                    <h5 className="m-0" style={{ color: 'red' }}>
                                                        Requires a Minimum Order Value of {coupon.min_spent}
                                                    </h5>
                                                )
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}

                            {activeTab === 'voucher' && (
                                <>
                                    <div className="coupons--apply--text d-flex justify-content-between mt-2">
                                        <p className="type--coupons--text m-0">
                                            Type coupon code here
                                        </p>
                                    </div>

                                    <div className="coupons--serch">
                                        <input
                                            type="text"
                                            placeholder="What’s most important to Know"
                                            style={{
                                                color: "white",
                                                padding: "10px",
                                            }}
                                        ></input>
                                    </div>
                                </>
                            )
                            }
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

function CarnivaPassOrderSummary(props) {
    const pass = props.pass;
    const passID = pass?.passDetails?.id || pass?.id;
    const passStat = props.passStat;
    const totalPassCount = props.totalPassCount;
    const totalPassAmount = props.totalPassAmount;
    let [platformFee, setPlatformFee] = useState(totalPassCount > 0 ? 5.9 : 0);
    let [tncAccepted, setTncAccepted] = useState(false);
    // console.log('Pass: ', passID, totalPassCount, totalPassAmount, passStat, pass,);
    let [userPassDetails, setUserPassDetails] = useState({});

    let [usableCarrot, setUsableCarrot] = useState(parseInt(userPassDetails?.usableCarrot || 0));
    // console.log('Carrot Usage: ', userPassDetails, user);

    let [usingCarot, setUsingCarot] = useState(true);
    let [grandTotal, setGrandTotal] = useState(totalPassAmount + platformFee - (usingCarot ? usableCarrot : 0));

    let [selectedCoupon, setSelectedCoupon] = useState(null);
    let [couponAmount, setCouponAmount] = useState(0);

    const customerList = [];
    const totalGenderList = [];

    useEffect(() => {
        loadUserPassDetails(
            {
                pass_id: passID,
                user_id: user?.id,
                female_qty: passStat[PassType.Female]?.count,
                couple_qty: passStat[PassType.Couple]?.count,
                male_qty: passStat[PassType.Male]?.count,
            },
            (pd) => {
                setUserPassDetails(pd);
                estimateOrder();
            }
        );
    }, []);

    const getOrderInput = () => {
        if (totalPassCount <= 0) {
            return;
        }
        const orderForm = new FormData();
        orderForm.append("user_id", user?.id);
        orderForm.append("bid_id", "0");
        orderForm.append("coupon_code", selectedCoupon || "");
        orderForm.append("selected_pass_id", passID);
        orderForm.append("name[]", customerList);
        orderForm.append("gender[]", totalGenderList);
        orderForm.append("couple_qty", passStat[PassType.Couple]?.count || 0);
        orderForm.append("male_qty", passStat[PassType.Male]?.count || 0);
        orderForm.append("female_qty", passStat[PassType.Female]?.count || 0);
        orderForm.append("carrot_use", usingCarot);
        // orderForm.append("pay_percent", 100);

        return orderForm;
    }

    const placeOrder = async (e) => {
        const orderForm = getOrderInput();
        if (orderForm) {
            if (!tncAccepted) {
                alert('Please review and accept the Terms & Conditions');
                return;
            }

            const orderResult = await Commons.doFetch('APIs/Carnival/PlaceOrder', orderForm);
            console.log('Order Result: ', orderResult);

            if (orderResult?.status === true && orderResult?.data && orderResult.data.length > 0) {
                const order = orderResult.data[0];
                console.log('Order Placed: ', order);

                const oID = order.order_id;
                // setOrderID(oID);

                const target = `/payCarnivalPass/${oID}`;
                window.location.href = target;
            } else {
                alert(`Problem: ${orderResult.msg}`);
            }
        } else {
            alert('Please specify the number of passes you would like to book.');
        }
    }

    const estimateOrder = async (e) => {
        const orderForm = getOrderInput();
        if (orderForm) {
            const orderResult = await Commons.doFetch('APIs/Carnival/EstimateOrder', orderForm);
            const estimate = orderResult?.data;

            console.log('Order Estimate: ', estimate);
            setCouponAmount(Number.parseFloat(estimate?.discounted_amount || '0'));
            setPlatformFee(Number.parseFloat(estimate?.platform_fee || '0'));
            setGrandTotal(Number.parseFloat(estimate?.net_payable_amount || '0'));
            setUsableCarrot(Number.parseFloat(estimate?.carrot_used_amount || '0'));

            // alert('Order Estimated');
        }
    }

    // prep content
    let content = (
        <p style={{ width: '100%', backgroundColor: 'black', padding: '20px' }}>
            {totalPassCount > 0 && (
                <>
                    <div className="carret--use">
                        <div className="carret--checkbox">
                            <input
                                type="checkbox"
                                checked={usingCarot === true}
                                onChange={(evt) => {
                                    usingCarot = evt.target.checked;
                                    setUsingCarot(evt.target.checked);
                                    estimateOrder();
                                }}
                            />
                            <label className="m-0" htmlFor="checkbox">
                                Use Carrot Balance
                            </label>
                        </div>
                        <p className="m-0">
                            Available Carrot Balance:{" "}
                            <span>
                                <img src={carrotIcon} />{" "}
                                {usingCarot === true ? (
                                    <>{user.virtual_wallet - usableCarrot}</>
                                ) : (
                                    <>{user.virtual_wallet}</>
                                )}
                            </span>{" "}
                        </p>
                    </div>

                    <CarnivaPassCoupons
                        pass={userPassDetails}
                        stat={passStat}
                        grandTotal={totalPassAmount}
                        setSelected={(coupons) => {
                            selectedCoupon = coupons;
                            setSelectedCoupon(coupons);
                            estimateOrder();
                        }}
                    />

                    {selectedCoupon && (
                        <div className="new--year--order--cart1">
                            <p className="New--year--page--add--more">Applied Coupon: {selectedCoupon}</p>
                        </div>
                    )}
                </>
            )}

            <div className="p--button">
                <div className="new--year--order--cart1">
                    <p className="New--year--page--add--more">No of Passes</p>
                    <p className="New--year--page--add--more">
                        {totalPassCount}
                    </p>
                </div>
                <div className="new--year--order--cart1">
                    <p className="New--year--page--add--more">Gross Pass Value</p>
                    <p className="New--year--page--add--more">
                        ₹{totalPassAmount.toFixed(CDC)}
                    </p>
                </div>

                {totalPassCount > 0 && (
                    <>
                        <div className="new--year--order--cart1">
                            <p className="New--year--page--add--more">Carrots Used</p>
                            <p className="New--year--page--add--more">
                                <span>- ₹{usingCarot ? userPassDetails.usableCarrot : 0}</span>
                            </p>
                        </div>
                        <div className="new--year--order--cart1">
                            <p className="New--year--page--add--more">Discount</p>
                            <p className="New--year--page--add--more">
                                <span>- ₹{(selectedCoupon !== null && couponAmount.toFixed) ? couponAmount.toFixed(CDC) : 0}</span>
                            </p>
                        </div>

                        <div className="new--year--order--cart1">
                            <p className="New--year--page--add--more">
                                Platform & Other Charges:
                            </p>
                            <p className="New--year--page--add--more">₹{platformFee}</p>
                        </div>
                    </>
                )}

                <div className="new--year--page--border--line1-1"></div>
                <div className="new--year--your--order--1--2">
                    <p className="New--year--page--add--more">Grand Total </p>
                    <p className="New--year--page--add--more">
                        ₹{" "}{grandTotal.toFixed(CDC)}
                    </p>
                </div>
                <div className="new--year--page--border--line1-1"></div>

                <CarnivalPassTnC />
                <div style={{ width: '200px', margin: 'auto' }}>
                    <h4>
                        <input
                            type='checkbox'
                            checked={tncAccepted === true}
                            onChange={(evt) => {
                                tncAccepted = evt.target.checked;
                                setTncAccepted(tncAccepted);
                            }}
                        /> I accept
                    </h4>
                </div>
                <button
                    className="summery--page--submit--pay--now"
                    onClick={placeOrder}
                    style={{ padding: '20px', fontSize: 'xx-large' }}
                // disabled={!tncAccepted}
                >
                    Place Order
                </button>
            </div>
        </p>
    );

    return content;
}

export default function CarnivalPassBookingInput(props) {
    const pass = props.pass;
    // console.log('Pass: ', pass);

    const pricing = pass?.pricing;

    const [passStat, setPassStat] = useState({});
    const [totalPassCount, setTotalPassCount] = useState(0);
    const [totalPassAmount, setTotalPassAmount] = useState(0);

    const updatePassStat = (category, count, action = 1 /* 1:increase, 0: set, -1:decrease */) => {
        const type = category.ticket_type;
        const typeStat = passStat[type] || { count: 0, amount: 0 };

        switch (action) {
            case 1:
                typeStat.count += count;
                break;
            case -1:
                typeStat.count -= count;
                break;
            case 0:
            default:
                typeStat.count = count;
        }

        // check for limits
        const limit = Number.parseInt(category.seat || 0);
        if (typeStat.count < 0) {
            typeStat.count = 0;
        } else if (typeStat.count > limit) {
            typeStat.count = limit;
        }

        // set amount
        typeStat.amount = category.price * typeStat.count;

        passStat[type] = typeStat;
        setPassStat(passStat);

        let totalCount = 0;
        let totalAmount = 0;
        Object.values(passStat || {}).forEach(ps => {
            totalCount += ps.count;
            totalAmount += ps.amount;
        });

        setTotalPassAmount(totalAmount);
        setTotalPassCount(totalCount);
    }

    const getPassStat = (category) => {
        const type = category.ticket_type;
        const typeStat = passStat[type] || { count: 0, amount: 0 };
        return typeStat;
    }

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "auto",
                    backgroundColor: "black",
                    color: "white",
                    zIndex: 100,
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onKeyDown={(e) => {
                    e.stopPropagation();
                }}
            >
                <List>
                    <ListItem
                        disablePadding // This ensures there's no default padding
                        dense
                        sx={{
                            "&:hover": {
                                backgroundColor: "inherit",
                            },
                        }}
                    >
                        <ListItemButton
                            onClick={(e) => e.stopPropagation()}
                            disableRipple
                            sx={{
                                "&:hover": {
                                    backgroundColor: "inherit", // Set the background color on hover if needed
                                },
                            }}
                        >
                            <ListItemText
                                primary={
                                    <div className="pricing--strip">
                                        <div className="mb-5">
                                            <div className="club--detail--and--pass--details">
                                                <h4>{pass?.name}</h4>
                                                <h5>
                                                    <ClubCard club={pass.club} />
                                                </h5>
                                            </div>

                                            {
                                                pricing.map(
                                                    price => {
                                                        const isSoldOut = (Number.parseFloat(price.seat || '0') <= 0);
                                                        if (isSoldOut) {
                                                            return undefined;
                                                        } else {
                                                            return (
                                                                <div className="ticket--type--data--set">
                                                                    <p className="m-0 mt-2">
                                                                        {price.ticket_type} @ {price.price}
                                                                    </p>
                                                                    <>
                                                                        <div className="new--year--add--number--page1">
                                                                            <div>
                                                                                <p
                                                                                    className="new--year--add--number--page1--p1 "
                                                                                    onClick={() => {
                                                                                        updatePassStat(price, -1);
                                                                                    }}
                                                                                >
                                                                                    -
                                                                                </p>
                                                                            </div>
                                                                            <div>
                                                                                <p className="new--year--add--number--page1--p2 ">
                                                                                    <input
                                                                                        key={`${price.ticket_type}${Math.random() * Math.random()}`}
                                                                                        defaultValue={getPassStat(price).count}
                                                                                        onBlur={(evt) => {
                                                                                            let val = Number.parseInt(evt.target.value || '0');
                                                                                            updatePassStat(price, val, 0);
                                                                                        }}
                                                                                        size='1'
                                                                                        style={{
                                                                                            textAlign: 'center'
                                                                                        }}
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                            <div>
                                                                                <p
                                                                                    className="new--year--add--number--page1--p1"
                                                                                    onClick={() => {
                                                                                        updatePassStat(price, 1);
                                                                                    }}
                                                                                >
                                                                                    +
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                )
                                            }
                                            <div className="ticket--type--data--set">
                                                <CarnivaPassGuestList
                                                    key={`GuestList${Math.random()}`}
                                                    passStat={passStat}
                                                    totalPassAmount={totalPassAmount}
                                                    totalPassCount={totalPassCount}
                                                    pass={pass}
                                                />
                                            </div>
                                            <div className="ticket--type--data--set">
                                                <CarnivaPassOrderSummary
                                                    key={`DueSummary${Math.random()}`}
                                                    passStat={passStat}
                                                    totalPassAmount={totalPassAmount}
                                                    totalPassCount={totalPassCount}
                                                    pass={pass}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </>);
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Commons from "./../Commons";
import './carnival-pass-card.css';
import { CarnivalAPI } from "./CarnivalAPIs";
import { CarnivalPassBookButton } from "./CarnivalPassBookButton";
import ClubCard from "./ClubCard";
import recommended from './image/recommended.jpg';
import soldout from './image/soldout.png';
import tick from './image/tick.png';

import { AiOutlineCalendar } from "react-icons/ai";
import group from "../Assets/Group.svg";
import './Coupons.css';

const PassType = CarnivalAPI.PassType;
const user = Commons.getUserData();

export default function CarnivalPassCard(props) {
  const { pass, sponsored } = props;
  const [coupons, setCoupons] = useState({});

  // console.log('Carnival Pass and Coupons: ', pass);

  useEffect(() => {
    CarnivalAPI.loadUserPassDetails(
      {
        pass_id: pass.id,
        user_id: user?.id,
        female_qty: 1,
        couple_qty: 1,
        male_qty: 1,
      },
      (pd) => {
        setCoupons(pd?.AvailableCoupons);
      }
    );
  }, []);

  const [similarPassCount, setSimilarPassCount] = useState(1);

  const getApiUrl = "https://partywitty.com/master/Api/getData/countCarnivalPassOfType";
  useEffect(() => {
    const offer_type = pass.type;
    const club_id = pass.club.id;

    fetch(`${getApiUrl}?type=${offer_type}${club_id ? `&club_id=${club_id}` : ''}`)
      .then((response) => response.json())
      .catch(e => { })
      .then((response) => {
        // console.log('All Similar Pass Count: ', response);

        if (response.status === "true") {
          setSimilarPassCount(1);
        } else {
          let result = response.data || {};
          setSimilarPassCount(result.count);
        }
      })
      .catch((error) => {
        // console.error("Error fetching categories:", error);
      });
  }, []);

  const buildViewDetails = () => {
    // console.log('Pass, Pass Details: ', pass, PD);
    const link = CarnivalAPI.getFullViewList(pass);

    return (
      <div className="new--year--page--more--detail">
        <Link to={link} >
          View Details
        </Link>
      </div>
    );
  }

  const buildIcon = () => {
    const tags = (pass.entertainment || '').split(',');

    return (
      <div className="carnival-pass-icon ">
        <img
          src={Commons.getUploadPath(pass.icon)}
          className="carnival-pass-icon"
        />
        <div className="carnival-pass-tags">
          {
            tags?.length > 0 && tags.map(
              tag => {
                return (
                  <div className="carnival-pass-tag">
                    {tag}
                  </div>
                );
              }
            )
          }
        </div>
        <div className="carnival-pass-timing carnival-pass-tag">
          <span><AiOutlineCalendar /> {pass?.event_date} | {pass?.start_time} </span>
        </div>
        {sponsored === true &&
          (
            <div className="carnival-pass-sponsored">
              <img src={recommended} alt="Sponsored" />
            </div>
          )
        }
      </div >

    );
  }

  const buildLine = () => {
    return (<div className="new--year--page--border--line"></div>);
  }

  const buildBullets = () => {
    const bullet_points = [];

    let alcCount = 0;
    for (let alcType of CarnivalAPI.Alcohols) {
      const alcohols = pass[alcType];
      if (alcohols) {
        const items = alcohols?.split(',');
        alcCount += items.length;
      }
    }

    if (alcCount > 1) {
      bullet_points.push(`${alcCount - 1}+ Alcoholic Beverages`);
    }

    return (
      <div style={{ display: "flex", flexDirection: 'row' }}>
        {bullet_points?.length > 0 &&
          bullet_points.map(
            point => {
              return (
                <div style={{ width: "100%" }}>
                  <li>{point}</li>
                </div>
              );
            }
          )
        }

      </div>
    );
  }

  const buildTicks = () => {
    const tick_points = [];

    let isMale = false, isFemale = false, isCouple = false;
    pass.pricing?.forEach(p => {
      switch (p.ticket_type) {
        case PassType.Couple:
          isCouple = true;
          break;
        case PassType.Male:
          isMale = true;
          break;
        case PassType.Female:
          isFemale = true;
          break;
      }
    });

    if (isMale) {
      tick_points.push('Male stag allowed');
    }

    if (isFemale) {
      tick_points.push('Female stag allowed');
    }

    if (!isMale && !isFemale && isCouple) {
      tick_points.push('Couples only');
    }

    if (pass.kids_friendly === 'true') {
      tick_points.push('Kids Friendly');
    }

    return (
      <div style={{ display: "flex", flexDirection: 'column' }}>
        {tick_points?.length > 0 &&
          tick_points.map(
            point => {
              return (
                <div style={{ width: "100%", color: 'rgb(0, 126, 125)' }}>
                  <img src={tick} style={{ width: '10px', height: 'auto' }} /><span>&nbsp;&nbsp; {point}</span>
                </div>
              );
            }
          )
        }

      </div>
    );

  }

  const buildPricings = () => {

    const Pricings = pass.pricing?.map(price => {
      const isSoldOut = (Number.parseFloat(price.seat || '0') <= 0);
      return (
        <div
          className="carnival-pass-pricing"
          key={price.name}
        >
          <div style={{ width: '30%' }}>
            {price.ticket_type}
          </div>
          <div style={{ width: '30%', }}>
            {isSoldOut && (<img src={soldout} style={{ width: '100px', height: 'auto' }} />)}
          </div>
          <div style={{ width: '40%', fontSize: 'small', textAlign: "right" }}>
            @ {(price.mrp !== price.price) && (<span style={{ textDecoration: 'line-through' }}> {price.mrp} </span>)} <span style={{ fontWeight: 'bold' }}> {price.price} </span> Each
          </div>
        </div>
      )
    });

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {Pricings}
      </div>
    );
  }

  const buildCoupons = () => {

    if (coupons?.length > 0) {
      const coupon = coupons[0];
      const FirstCoupon = (
        <div className="carnival-pass-pricing carnival-pass-coupons">
          <div style={{ width: '10%' }}>
            <img src={coupon.img ? (Commons.URL.Upload + coupon.img) : group}
              style={{ width: '30px', height: 'auto' }}
            />
          </div>
          <div style={{ width: '70%', fontSize: 'small' }}>
            <p className="m-0">
              {coupon.discount_type === "amount" ? (
                <span>UP TO {coupon.discount} OFF.</span>
              ) : (
                <span>
                  {coupon.discount}% OFF.
                </span>
              )}
              <span>
                &nbsp; Use Code : <span style={{ fontWeight: 'bold' }}>{coupon.code}</span>
              </span>
            </p>

          </div>
          <div style={{ width: '20%', fontSize: 'small' }}>
            {coupons?.length > 1 && (<span>+{coupons?.length - 1} more Coupons</span>)}
          </div>
        </div>
      );

      return FirstCoupon;

    } else {
      return (<></>);
    }
  }

  const buildContent = () => {

    return (
      <div className="carnival-pass-content">
        <div className="carnival-pass-name">
          {pass.name}
        </div>
        <ClubCard club={pass.club} />
        {buildLine()}

        {buildBullets()}
        {buildTicks()}
        {buildLine()}

        {buildViewDetails()}
        {buildLine()}

        {buildPricings()}
        {buildCoupons()}
        {buildLine()}
      </div>
    );
  }

  const buildSimilar = () => {
    if (similarPassCount > 1 && props.hideSimilar !== true) {
      return (
        <Link to={`/carnival/${pass.type}/${pass.club.id}`}>
          <div className="carnival-pass-similar">
            {similarPassCount - 1} More Option Available
          </div>
        </Link>
      );
    } else {
      return undefined;
    }
  }

  return (
    <>
      <div
        className="carnival-pass-card"
        key={pass.id}
      >
        <Link to={CarnivalAPI.getFullViewList(pass)}
          onClick={
            (evt) => {
              // evt.preventDefault();
            }
          }>
          {buildSimilar()}
          {buildIcon()}
          {buildContent()}
        </Link>
        <div style={{
          position: 'absolute',
          bottom: '5px',
          left: '5px',
          width: 'calc(100% - 10px)',
        }}>
          <CarnivalPassBookButton pass={pass} />
        </div>

      </div>
    </>
  );
}

function Coupon(props) {
  const coupon = props.coupon;

  return (
    <div className="coupons--code--design">
      <div
        className={`coupons--card mb-2 `}
        key={coupon.id}
        value={coupon.code}
      >
        <div className="coupons--headericon">
          <div className="coupon--header--icon">
            <img src={coupon.img ? (Commons.URL.Upload + coupon.img) : group}></img>
          </div>
          <div className="dis--card--coupons">
            <div className="off--discount--text">
              {coupon.discount_type === "amount" ? (
                <h5 className="m-0">
                  UP TO {coupon.discount} OFF
                </h5>
              ) : (
                <h5 className="m-0">
                  {coupon.discount}% OFF UP TO{" "}
                  {coupon.capping}
                </h5>
              )}

              <p className="m-0">
                Save {coupon.capping} with this code
              </p>
              <div className="c--code">
                <h3>{coupon.code}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

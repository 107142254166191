import React, { useEffect, useState } from "react";
import './club-card.css';
import Commons from "../Commons";
import { Link } from "react-router-dom";
import star from "./../Assets/star.png";

export default function ClubCard(props) {
    const club = props.club;
    const [distance, setDistance] = useState(undefined);

    useEffect(
        () => {
            if (!club) {
                return;
            }
            const cLat = club.latitude || club.area.latitude;
            const cLong = club.longitude || club.area.longitude;
            if (cLat >= 0 && cLong >= 0) {
                Commons.findDistance(cLat, cLong)
                    .then(dist => {
                        setDistance(dist);
                    })
                    .catch(e => { });
            }
        },
        []
    );
    // console.log('Club Details: ', { ...club });

    if (!club) {
        return undefined;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '70%' }}>
                <Link to={''}><div style={{ color: '#D71362' }}>{club.name}</div></Link>
                <div style={{ fontSize: 'small' }}><Link to={''}>{club.area.name}</Link>, <Link to={''}>{club.area.city_name}</Link></div>
            </div>
            <div style={{ width: '30%', textAlign: 'right', position: 'relative' }}>
                <div style={{ position: "absolute", right: '0px' }}>
                    <div className="club-rating" style={{ textAlign: 'left', }}>
                        <img src={star} alt="Star Icon" />
                        <span> {club.ratings?.averageTotal}</span>
                    </div>
                    <div>
                        {distance >= 0 &&
                            (
                                <span> {distance} KM</span>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, { useEffect, useState } from "react";
import Commons from "../Commons";
import { MdFavorite } from "react-icons/md";
import { FaDirections } from "react-icons/fa";
import { BsFacebook, BsShareFill, BsWhatsapp } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
import { AiOutlineMail } from "react-icons/ai";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
// import "react-responsive-carousel/lib/styles/carousel.min.css";


export default function LikeShare(props) {
    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [showResponse, setShowResponse] = useState(false);
    const [bookdata, setBookData] = useState([]);
    const [data, setData] = useState(props.data);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [clubId, setClubId] = useState(null);
    const [isFavorite, setIsFavorite] = useState(false);

    const currentURL = window.location.href;
    const shareText = `${(data?.shareMsg || "Check this out.")}`;

    const handleShare = (platform) => {
        let shareURL = "";

        if (platform === "facebook") {
            shareURL = `https://www.facebook.com/sharer/sharer.php?u=${currentURL}&quote=${shareText}`;
        } else if (platform === "whatsapp") {
            shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
                `${shareText}: ${currentURL}`
            )}`;
        } else if (platform === "twitter") {
            shareURL = `https://twitter.com/intent/tweet?url=${currentURL}&text=${encodeURIComponent(
                shareText
            )}`;
        } else if (platform === "email") {
            shareURL = `mailto:?subject=${encodeURIComponent(
                shareText
            )}&body=${encodeURIComponent(`${shareText}: ${currentURL}`)}`;
        }

        window.open(shareURL, "_blank");
    };

    useEffect(() => {
        Commons.getMyGeo().then(
            geo => {
                setLatitude(geo.latitude);
                setLongitude(geo.longitude);
            }
        );

    }, []);

    const addToFavorite = async (e) => {
        try {
            const userdata = Commons.getUserData();
            if (!userdata) {
                setResponseMessage("User data is missing");
                return;
            }
            const formDataToSend = new FormData();
            formDataToSend.append("club_id", data?.club_id);
            formDataToSend.append("user_id", userdata.id);
            const response = await fetch(
                "https://partywitty.com/master/Api/sendData/addClubFavourite",
                {
                    method: "POST",
                    body: formDataToSend,
                }
            );
            const responseData = await response.json();
            if (responseData.status === true) {
                setIsFavorite(!isFavorite);
                setResponseMessage(responseData.msg);
                setBookData(responseData.data);
                if (responseData.data) {
                } else {
                    setShowResponse(true);
                }
            } else {
                setResponseMessage("Error in processing the request");
            }
        } catch (error) {
            setResponseMessage("An error occurred");
        }
    };


    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 300,
        bgcolor: "background.paper",
        borderRadius: 1,
        boxShadow: 24,
        p: 1,
    };

    return (
        <>
            <div class="SocialShareIcon" style={{ position: 'relative' }}>
                {(data?.latitude && data?.longitude) && (
                    <a
                        rel="noreferrer"
                        href={`https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${data?.latitude},${data?.longitude}&travelmode=driving`}
                        target="_blank"
                    >
                        <button className="direction-icon">
                            <FaDirections />
                        </button>
                    </a>
                )}
                {
                    (data?.club_id) && (
                        <button className="direction-icon2"
                            onClick={addToFavorite}
                            style={{ color: isFavorite ? "red" : "white" }}
                        >
                            <MdFavorite />
                        </button>
                    )
                }
                <button onClick={handleOpen2} className="direction-icon1" >
                    <BsShareFill />
                </button>
            </div>
            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="share-menu">
                        <p className="facebook" onClick={() => handleShare("facebook")}>
                            <BsFacebook />
                        </p>
                        <p className="whatsapp" onClick={() => handleShare("whatsapp")}>
                            <BsWhatsapp />
                        </p>
                        <p className="twitter" onClick={() => handleShare("twitter")}>
                            <FaSquareXTwitter />
                        </p>
                        <p className="gmail" onClick={() => handleShare("email")}>
                            <AiOutlineMail />
                        </p>
                    </div>
                </Box>
            </Modal>
        </>

    );
}
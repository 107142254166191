import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CarnivalAPI } from "./CarnivalAPIs";
import "./carnival-details.css";
import { Carousel } from "antd";
import { AiOutlineCalendar } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import { BsAlarm } from "react-icons/bs";
import minus from './image/minus.png';
import { CarnivalPassBookButton } from "./CarnivalPassBookButton";
import soldout from './image/soldout.png';
import Commons from "../Commons";
import CarnivalPassCard from "./CarnivalPassCard";
import ClubCard from "./ClubCard";
import LikeShare from "../Common/LikeShare";
import moment from "moment";
import CarnivalPassTnC from "./CarnivalPassTnC";
import { SliderThumb } from "@mui/material";
import { Helmet } from "react-helmet-async";

const url = "https://partywitty.com/master/assets/uploads/";

export default function CarnivalPassDetails(props) {
    const passProp = props?.pass;
    let { id, area, city, club } = useParams();
    if (!id && club) {
        id = club.substring(club.lastIndexOf('--') + 2);
        club = club.substring(0, club.lastIndexOf('--'));
    }
    const [pass, setPass] = useState([]);

    const [isContentVisible, setIsContentVisible] = useState(false);
    const handleDescriptionClick = () => {
        setIsContentVisible(!isContentVisible);
    };

    useEffect(() => {
        if (passProp) {
            id = passProp.id;
            setPass(passProp);
        }
    }, [passProp]);

    useEffect(() => {
        CarnivalAPI.loadUserPassDetails({ pass_id: id }, (pass) => {
            // console.log('View Pass with Details: ', pass)
            setPass(pass);
        });
    }, [id]);

    const [similarPasses, setSimilarPasses] = useState([]);

    // Fetch all Carnival Pass of a Type
    useEffect(() => {
        const offer_type = pass?.type;
        const club_id = pass?.club?.id;

        Commons.doFetch(`Api/getData/allCarnivalPassOfType?type=${offer_type}${club_id ? `&club_id=${club_id}` : ''}`)
            .then((response) => {

                if (response.status === "true") {
                    setSimilarPasses([]);
                } else {
                    let passes = response.data || [];
                    const similar = [];
                    passes.forEach(p => {
                        p = { ...p, ...p.passDetails };
                        if (p.id !== pass.id) {
                            similar.push(p);
                        }
                    });
                    setSimilarPasses(similar);
                    // console.log('Similar Carnival Passes: ', pass, similar);
                }
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
            });
    }, [pass]);


    const getDuartion = (startDate, startTimeStr, endTimeStr) => {
        let duration = '';
        if (startTimeStr && endTimeStr) {
            // console.log('Duaration: ', startDate, startTimeStr, endTimeStr);

            let dtStart = moment(`${startDate} ${startTimeStr}`);
            let dtEnd = moment(`${startDate} ${endTimeStr}`);
            if (dtEnd.isBefore(dtStart)) {
                dtEnd.add(1, 'day');
            }

            const timeDifference = dtEnd.diff(dtStart);
            const hours = Math.floor(timeDifference / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            duration = (`${hours} hours ${minutes} minutes`);
        }
        return duration;
    }

    const buildList = (text) => {
        text = text?.trim();
        if (!text || text.length === 0) {
            return undefined;
        } else {
            // console.log('List Text: ', text.length, text);
        }

        const items = text.split(',');
        if (items?.length > 0) {
            return (
                <ul>
                    {items.map(item => {
                        return (<li>{item.trim()}</li>);
                    })}
                </ul>
            );
        } else {
            return undefined;
        }
    }

    const buildAlcoholList = (label, list,) => {
        const listView = buildList(list);
        // console.log('Alcohol: ', list, label, listView);
        if (listView) {
            return (
                <>
                    <li>{label}</li>
                    {listView}
                </>
            );
        } else {
            return listView;
        }
    }

    const buildSimilar = () => {
        if (similarPasses?.length > 0) {
            return (
                <>
                    <h3>Similar Passes</h3>
                    <div
                        className="new--year--special--package"
                    >
                        {
                            similarPasses.map((e) => ( // Carnival Pass Card
                                <CarnivalPassCard pass={e} hideSimilar={true} />
                            ))
                        }
                    </div>
                </>
            );
        } else {
            return undefined;
        }
    }

    if (!pass) {
        return (
            <div className="Artist-viewall-banner--img-8" style={{ backgroundColor: 'black', color: 'white' }}>
                <p>No content available</p>
            </div>
        );
    } else {
        const offer_type = pass?.type;
        const clubName = pass?.club?.name;
        const clubCity = pass?.club?.area?.city_name;

        const newYear = moment().year() + 1;
        const newYearTitle = `New Year Party at ${clubName} in ${clubCity} | Book Passes & Packages for ${newYear}`;
        const title = (offer_type === 'New Year') ? newYearTitle : undefined;

        const newYearDesc = `Celebrate New Year’s Eve ${newYear} at ${clubName} in ${clubCity}. Enjoy exclusive party passes, VIP packages, and electrifying events. Book now for music, dancing, and unforgettable moments!`;
        const desc = (offer_type === 'New Year') ? newYearDesc : undefined;

        return (
            <>
                <Helmet>
                    {title && (
                        <title>
                            {title}
                        </title>
                    )}
                    {desc && (
                        <meta
                            name="description"
                            content={desc}
                        />
                    )}
                </Helmet>
                <div className="carnival-view-all mt-2" style={{ backgroundColor: 'black', color: 'white', }}>
                    <div className="carnival-about-us-page" style={{ marginBottom: '10px' }}>
                        <div className="carnival-about-us-left">
                            <div className="Artist-viewall-banner-event">
                                <Carousel autoplay>
                                    {pass.icon ? (
                                        <div className="Artist-viewall-banner--img-8">
                                            <img alt="banner" src={url + pass.icon} />
                                        </div>
                                    ) : (
                                        <div className="Artist-viewall-banner--img-8">
                                            <p>No content available</p>
                                        </div>
                                    )}
                                </Carousel>


                            </div>
                        </div>
                        <div className="carnival-about-us-right">
                            <div className="carnival--about--us--details-8">
                                <div className="carnival--about--us--details1">
                                    <LikeShare
                                        data={{
                                            // club_id: pass?.club?.id,
                                            latitude: pass?.club?.latitude || pass?.club?.area.latitude,
                                            longitude: pass?.club?.longitude || pass?.club?.area.longitude,
                                            shareMsg: `Book ${pass?.name} Carnival Pass at ${pass?.club?.name}, ${pass?.club?.area?.name}, ${pass?.club?.area?.city_name} and enjoy ${offer_type}`, //'View this Carnival Pass',
                                        }}
                                        key={Math.random()}
                                    />
                                    <h2>
                                        {pass.name}
                                        <span> ({pass.type})</span>
                                    </h2>
                                    <ClubCard club={pass.club} key={Math.random()} />
                                </div>

                            </div>

                            <div className="carnival--about--us--carnival-time-detail">
                                <div className="carnival--about--us--details-8">
                                    <div>
                                        <p className="carnival--about--us--carnival-time-detail--11--p">
                                            <span>
                                                <AiOutlineCalendar /></span> {pass.event_date} | {pass.start_time}
                                        </p>
                                        <p className="carnival--about--us--carnival-time-detail--11--p">
                                            <span>  <MdLocationOn /></span>
                                            {pass.clubAddress}
                                        </p>
                                    </div>
                                    <div className="">
                                        <div className="carnival--about--us--carnival-time-detail--11">
                                            <span>
                                                <BsAlarm />
                                            </span>
                                            <div>
                                                <p className="carnival--about--us--carnival-time-detail--11-p">Duration</p>
                                                <p className="carnival--about--us--carnival-time-detail--11--p">{getDuartion(pass.event_date, pass.start_time, pass.end_time)}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <CarnivalPassBookButton pass={pass} />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ maxWidth: '90%', margin: 'auto' }}>
                        <div className="carnival-about-us-page" >
                            {pass.pricing?.map(
                                price => {
                                    const isSoldOut = (Number.parseFloat(price.seat || '0') <= 0);
                                    const soldoutStyles = isSoldOut ? {
                                        backgroundColor: 'rgba(255,0,0, 0.3)',
                                        backgroundImage: `url("${soldout}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundAttachment: 'fixed',
                                        backgroundSize: '100% 100%',
                                        color: 'black',
                                    } : undefined;

                                    return (
                                        <div style={{
                                            flex: '1',
                                            border: '1px solid white',
                                            borderRadius: '10px',
                                            padding: '10px',
                                            textAlign: 'center',
                                            maxWidth: "350px",
                                            // ...soldoutStyles,
                                        }}>
                                            <h3>{price.ticket_type}</h3>
                                            <div>
                                                Price: {(price.mrp !== price.price) && (<span style={{ textDecoration: 'line-through' }}> {price.mrp} </span>)} <span style={{ fontWeight: 'bold' }}> {price.price} </span>
                                            </div>
                                            {
                                                (!isSoldOut) ? (
                                                    <div>
                                                        <span style={{ fontWeight: 'bold' }}> {price.seat} </span> Seats Available
                                                    </div>
                                                ) : (
                                                    <img src={soldout} alt='Sold Out' style={{ width: '100px', height: 'auto' }} />
                                                )
                                            }
                                        </div>
                                    )
                                }
                            )}
                        </div>

                        <div className="carnival-about-us-page-content" >
                            <div className="carnival--about--us--About">
                                <h2>Alcoholic Beverages</h2>
                                <p >
                                    {pass.alcoholic_beverages}
                                </p>
                                <ul>
                                    {buildAlcoholList('Whiskey', pass.whiskey)}
                                    {buildAlcoholList('Vodka', pass.vodka)}
                                    {buildAlcoholList('Gin', pass.gin)}
                                    {buildAlcoholList('Rum', pass.rum)}
                                    {buildAlcoholList('Tequila', pass.tequila)}
                                    {buildAlcoholList('Beer', pass.beer)}
                                    {buildAlcoholList('Red Wine', pass.red_wine)}
                                    {buildAlcoholList('White Wine', pass.white_wine)}
                                    {buildAlcoholList('Rose Wine', pass.rose_wine)}
                                    {buildAlcoholList('Sparkling Wine', pass.sparkling_wine)}
                                    {buildAlcoholList('Champagne', pass.champagne)}
                                    {buildAlcoholList('Cocktails', pass.cocktails)}
                                </ul>
                            </div>

                            <div className="carnival--about--us--About">
                                <h2>Non-Alcoholic Beverages</h2>
                                <div dangerouslySetInnerHTML={{ __html: pass.non_alcoholic_beverages }}></div>
                            </div>

                            <div className="carnival--about--us--About">
                                <h2>Starters</h2>
                                <div dangerouslySetInnerHTML={{ __html: pass.food_starter }}></div>
                            </div>
                        </div>
                        <CarnivalPassTnC />
                        <div className="carnival-about-us-page-content" style={{ display: 'none' }}>
                            <div className="carnival--about--us--About">
                                <h2>
                                    {!isContentVisible && <span onClick={handleDescriptionClick}>+</span>}
                                    {isContentVisible && <span onClick={handleDescriptionClick}><img src={minus}></img></span>}
                                    Terms & Conditions
                                </h2>
                                {isContentVisible && (
                                    <div>
                                        <ol type='a'>
                                            <li><b>No refunds:</b> All ticket sales are final. No refunds will be issued, even in case of rescheduling.</li>
                                            <li><b>Security:</b> Security procedures, including frisking, will be strictly enforced.</li>
                                            <li><b>Prohibited Items:</b> For the safety of all attendees, please refrain from bringing any dangerous or potentially hazardous objects, such as weapons, knives, firearms, fireworks, helmets, laser devices, bottles, or musical instruments. Possession of such items may result in immediate ejection from the venue.</li>
                                            <li><b>Liability Waiver:</b> The sponsors, performers, and organizers are not liable for any injuries or damages that may occur during the event. Any disputes will be subject to the jurisdiction of Uttar Pradesh courts.</li>
                                            <li><b>Intoxication:</b> Individuals who appear to be intoxicated may be denied entry.</li>
                                            <li><b>Late Entry:</b> Organizers reserve the right to deny late entry to the event.</li>
                                            <li><b>Venue Rules:</b> Please adhere to all venue rules and regulations.</li>
                                        </ol>
                                        {/**
                                    <div dangerouslySetInnerHTML={{ __html: pass.tc }}></div>
                                     */}
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                    {buildSimilar()}
                </div>
            </>
        );
    }
}
import React, { useEffect, useState } from "react";
import "./HomepageTab.css";
import { Link } from "react-router-dom";
import table from "./image/table1.png";
import artist from "./image/artist.png";
import group1 from "./image/package.png";
import diwali_carnival from "./image/diwali-2024.webp";
import new_year_carnival from "./image/happy-new-year.png";
import christmas_carnival from "./image/merry-christmas.png";

import EventImage from "./image/event.png";
import LazyLoad from "react-lazy-load";
import moment from 'moment';

export default function HomePageTab() {

  const [carnivalTypes, setCarnivalTypes] = useState([]);
  useEffect(() => {
    const activeCarnivalTypes = "https://partywitty.com/master/Api/getData/activeCarnivalTypes";
    fetch(activeCarnivalTypes)
      .then((response) => response.json())
      .then((response) => {
        // console.log('All Active Carnival Types: ', response);

        if (response.status === "true") {
          setCarnivalTypes([]);
        } else {
          let types = response.data || [];
          setCarnivalTypes(types);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const CTs = [
    {
      type: 'New Year',
      icon: new_year_carnival,
      start: '2024-12-01',
      end: '2025-01-01',
    },
    {
      type: 'Christmas',
      icon: christmas_carnival,
      start: '2024-11-16',
      end: '2024-12-26',
    },
    {
      type: 'Valentine Day',
      icon: diwali_carnival,
      start: '2024-01-15',
      end: '2024-02-15',
    },
  ];

  const buildCTs = () => {
    const isActive = (ct) => {
      if (ct.start && ct.end) {
        const today = moment();
        const thisyear = today.year();
        const start = moment(ct.start);
        const end = moment(ct.end);
        const match = (today.isSameOrBefore(end) && today.isSameOrAfter(start));
        return match;
      }
      return true;
    }

    return CTs.map(ct => {
      if (!isActive(ct)) {
        return undefined;
      }

      return (
        <div style={{ color: 'white', }}>
          <Link
            to={`/carnival/${ct.type}`}
            style={{ textDecoration: "none" }}
            className="bookatable-routes"
          >
            <div className="services-name">
              <img src={ct.icon} alt={`${ct.type} Carnival Offers`} />
              <p>{ct.type} Carnival</p>
            </div>
          </Link>
        </div>
      );
    });
  }

  return (
    <LazyLoad offset={5}>
      <div className="services">

        {buildCTs()}
        <Link
          to="/party-packages-delhi-ncr-view-all"
          style={{ textDecoration: "none" }}
          className="bookatable-routes"
        >
          <div className="services-name">
            <img src={group1} alt="Birthday Party Packages Near Me" />
            <p>Party Package</p>
          </div>
        </Link>
        <Link
          to="/book-a-table-delhi-ncr-view-all"
          style={{ textDecoration: "none" }}
          className="bookatable-routes"
        >
          <div className="services-name">
            <img src={table} alt="Table Booking" />
            <p>Book a Table</p>
          </div>
        </Link>
        <Link
          to="/event-delhi-ncr-view-all"
          style={{ textDecoration: "none" }}
          className="bookatable-routes"
        >
          <div className="services-name">
            <img src={EventImage} alt="Events" />
            <p>Events</p>
          </div>
        </Link>
        <Link
          to="/artist-view-all"
          style={{ textDecoration: "none" }}
          className="bookatable-routes"
        >
          <div className="services-name">
            <img src={artist} alt="Table Booking" />
            <p>Artist</p>
          </div>
        </Link>
      </div>
    </LazyLoad>
  );
}
import "./Favorate--hangout.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import star from "./image/star.png";
import Carousel from "react-bootstrap/Carousel";
import filterimg2 from "./image/filterimg2.png";
import banner8 from "./image/banner8.jpg";
// import banner8 from "./image/party-packages-delhi-ncr-view-all.jpg";
import logo from "../Assets/logo.png";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";
import VisitingTableVenue from "../Visiting--table-venue/Visiting--table";
import { Base64 } from "js-base64";

export default function GroupBookingThumbnail() {

  const Targetting_content = `Discover the perfect party package tailored to your celebrations! Whether it's a birthday bash, corporate event, or special occasion, our customizable party packages offer everything you need for an unforgettable gathering. Party Witty presents an array of enticing party packages designed for avid party enthusiasts. Our diverse range encompasses both alcoholic and non-alcoholic options, along with the flexibility to select between delectable veg and non-veg culinary delights, catering to every palate at the top-notch venues across Delhi,Ncr including cafes, clubs, lounges, fine-dines etc. These meticulously crafted party packages are perfect for commemorating various occasions, including birthdays, anniversaries, reunion gatherings, and celebratory milestones such as promotions. Embrace the convenience and flair of our party packages, elevating your group celebrations to extraordinary heights. 
  The different type of party packages that Party witty include are:
  Imported Premium Drinks + Starters- Savor the exceptional quality and exquisite flavors of our imported premium and starters, setting the standard for culinary excellence at your fingertips.
  Veg and Non-Veg Food only- Explore our party packages offering a delightful mix of vegetarian and non-vegetarian cuisine, ensuring a flavorful experience for all your guests.
  Single Malt Premium Drinks + Food- Relish the ultimate mix of refinement and flavor with our carefully chosen premium plus food options and our exclusive single malt premium beverages, making for an unmatched dining experience.
  Single Malt Drinks + Food- Host a luxurious affair with our single malt drinks and food party packages. From elegant decor to exquisite cuisine, we ensure your event is a tasteful celebration of refined tastes.
  Cafe Menu- Discover a delightful selection of party packages on our café menu, designed to cater to your every celebration with delectable cuisine and personalized service.
  High Tea- Experience the epitome of refined indulgence with our high tea offerings, featuring a delightful ensemble of gourmet delights and premium teas in a charming ambiance.
  Veg Only- Immerse yourself in a world of culinary delight with our exclusive vegetarian food selection, crafted with care to delight your senses and satisfy your cravings.
  Non-Veg Only- Experience a symphony of flavors with our non-vegetarian delights, expertly prepared to tantalize your taste buds and elevate your dining experience.
  IMFL Premium Drinks + Food- Immerse yourself in a delightful fusion of IMFL (Indian Made Foreign Liquor) premium drinks and exquisite cuisine, where each sip and bite harmonize to create a symphony of flavors, promising an extraordinary dining experience like no other.
   IMFL Drinks + Food- Elevate your dining experience with a curated selection of IMFL and delicious food pairings, guaranteed to tantalize your senses.
   Imported Premium Drinks + Food- Embark on a culinary journey like no other as you pair imported premium drinks with exquisite dishes, creating an experience that is as refined as it is indulgent.
   Imported Drinks + Food- Discover the art of pairing imported drinks with culinary masterpieces, unlocking a world of rich flavors and unparalleled dining pleasures.
  
  The different list of occasions you can celebrate through these party packages:
  Birthday Party- Celebrate your special day in style with our all-inclusive  party package, designed to create unforgettable memories filled with joy, laughter, and delightful surprises.
  Anniversary Party- Celebrate your love story with an unforgettable anniversary party, where every detail is crafted to reflect your unique journey together with our party packages.
  Corporate Party- Elevate your corporate event with our tailored party packages, designed to impress clients, reward employees, and create unforgettable networking opportunities.
  Kids Party- Make your child's birthday unforgettable with our customized kids' party packages, designed to spark imagination, laughter, and joy in every young guest.
  Kitty Party- Host the perfect kitty party with our comprehensive packages, designed to create a fun and vibrant atmosphere with delicious food, lively music, and engaging activities.
  Reunion Party- Reconnect and reminisce your reunion with our party packages, designed to bring together old friends, create new memories, and celebrate the bonds that last a lifetime.
  Freshers- Welcome new beginnings with our vibrant party packages, designed to kick-start your college journey with fun, laughter, and memorable moments. 
  Farewell- Create lasting memories with our exclusive party packages, designed to capture the essence of your time together and send you off with warm wishes and fond farewells.
  Cocktail Party-Sip and socialize in style with our tailored  party packages, offering a selection of artisanal drinks, tantalizing snacks, and a vibrant ambiance for your cocktail party.
  Bachelor/Bachelorette Party- Experience an unforgettable night out for your bachelor/bachelorette party packages, complete with trendy venues, top-notch entertainment, and indulgent treats.
  
  Book your party package with us today and let us turn your celebration into an unforgettable experience. Don't wait, book now and let's make your party dreams a reality!
  `;
  const [isReadmore, setReadmore] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [venueData, setVenueData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectedMenuIds, setSelectedMenuIds] = useState([]);
  const [data2, setData2] = useState([]);

  const [selectedMenus, setSelectedMenus] = useState({
    2: "Restaurant & Bar",
  });
  const [selectedTitle, setSelectedTitle] = useState(
    "Imported Drinks + Food"
  );

  const url = "https://partywitty.com/master/assets/uploads/";

  console.log(selectedMenuIds, "selectedMenuIds");

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude.toString());
          setLongitude(longitude.toString());
          fetchData(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/ClubPackage/getVenuType")
      .then((response) => {
        setVenueData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(" https://partywitty.com/master/APIs/ClubPackage/getPackageTypes")
      .then((response) => {
        setTitleData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleClick = (title) => {
    setSelectedTitle(title.name);
  };

  const fetchData = (latitude, longitude, selectedMenuIds = []) => {
    const formData = new FormData();
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("venue_id", selectedMenuIds.join(", "));
    formData.append("title_id", "");
    formData.append("keyword", keyword);

    axios
      .post(
        "https://partywitty.com/master/APIs/ClubPackage/allPackages",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          setData(response.data.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMenuClick = (menu) => {
    const updatedSelectedMenus = { ...selectedMenus };
    if (updatedSelectedMenus[menu.id]) {
      delete updatedSelectedMenus[menu.id];
    } else {
      updatedSelectedMenus[menu.id] = menu;
    }
    setSelectedMenus(updatedSelectedMenus);
  };

  useEffect(() => {
    const selectedMenuIds = Object.keys(selectedMenus).map((id) =>
      parseInt(id)
    );
    if (selectedMenuIds.length > 0) {
      fetchData(latitude, longitude, selectedMenuIds);
    }
  }, [selectedMenus, latitude, longitude]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings1 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleKeywordChange = (e) => {
    const { value } = e.target;
    setKeyword(value);
    handleKeywordChange1();
  };
  const handleKeywordChange1 = () => {
    fetchData(latitude, longitude, selectedMenuIds, keyword);
  };
  useEffect(() => {
    handleKeywordChange1();
  }, [keyword]);

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/APIs/Common/getWebPromotionalBanner")
      .then((response) => setData2(response.data.data))
      .catch((error) => console.log(error));
  }, []);

  const filteredData = data2.filter(
    (banner) =>
      banner.page_name === "Book a venue" && banner.position === "sponsored"
  );

  const generateURL = (landingPage, item) => {
    switch (landingPage) {
      case "":
        return "";
      case "party_packages":
        return "https://www.partywitty.com/party-packages-delhi-ncr-view-all";
      case "table_booking":
        return "https://www.partywitty.com/book-a-table-delhi-ncr-view-all";
      case "club_about":
        return `/${item.cityName
          .trim()
          .replace(/ /g, "-")
          .toLowerCase()}/${item.areaName
            .trim()
            .replace(/ /g, "-")
            .toLowerCase()}/${item.clubName
              .trim()
              .split(/ +/)
              .join("-")
              .replace(/ /g, "-")
              .toLowerCase()}`;
      default:
        return "";
    }
  };
  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href=" https://www.partywitty.com/party-package-delhi-ncr-view-all"
        />
        <title> Party Witty: Best Party Packages in Delhi NCR</title>
        <meta
          name="description"
          content="Are You Looking  for the Best Party Packages in Delhi Ncr With Price? Party Witty Will Provide You With a Discover and Book Perfect Party Planner Venue Online"
        />
      </Helmet>
      <VisitingTableVenue />
      <div className="Favorate--Hangout--page">
        <div className="Favorate--Hangout--page--card">
          <div className="Favorate--Hangout--page--card-banner-img">
            <img src={banner8}></img>
          </div>
          <div className="Favorate--Hangout--page--card-banner-Search8">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={logo}></img>
            </Link>
            <h1 className="Favorate--Hangout--page--p1">
              Get over 5000+ party packages in Delhi, Ncr
            </h1>

          </div>
        </div>
      </div>
      <div className="Favorate--hangout--club--all-gap">
        <div className="Favorate--hangout--sponsered--8">
          <Carousel interval={6000} indicators={false}>
            {filteredData.map((e) => (
              <Carousel.Item key={e.id} className="slider_img">
                {generateURL(e.landing_page, e) ? (
                  <Link to={generateURL(e.landing_page, e)}>
                    <img src={url + e.default_img} alt="img" />
                  </Link>
                ) : (
                  <img src={url + e.default_img} alt="img" />
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="Favorate--hangout--club--all-gap">
        <div className="favorate--club--category--10">
          <div className="Favorate--club--category--8">
            <Slider {...settings}>
              {venueData.map((venue, index) => (
                <div
                  key={venue.id}
                  className={`Favorate--club--category--card ${selectedMenus[venue.id] ? "selected2" : ""
                    }`}
                  onClick={() => handleMenuClick(venue)}
                >
                  <img
                    src={url + venue.img}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <p>{venue.name}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className="Favorate--club--category--8">
          <Slider {...settings1}>
            {titleData.map((title, index) => (
              <div
                className={
                  selectedTitle == title.name
                    ? "selected1"
                    : "Favorate--club--category--1"
                }
                key={index}
              >
                <button onClick={() => handleClick(title)}>
                  {" "}
                  {title.name} {` (${title.total_packages})`}{" "}
                </button>
              </div>
            ))}
          </Slider>
        </div>

        <div className="Favorate--club--category--8--mobile">
          <div className="services1">
            {venueData.map((venue, index) => (
              <div
                key={venue.id}
                className={`Favorate--club--category--card ${selectedMenus[venue.id] ? "selected2" : ""
                  }`}
                onClick={() => handleMenuClick(venue)}
              >
                <img src={filterimg2} alt="" />
                <p>{venue.name}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="Favorate--club--category--8--mobile">
          <div className="services1">
            {titleData.map((title, index) => (
              <div
                className={
                  selectedTitle === title.name
                    ? "selected1"
                    : "Favorate--club--category--1"
                }
                key={index}
              >
                <button onClick={() => handleClick(title)}>{title.name}</button>
              </div>
            ))}
          </div>
        </div>
        <div className="Group--booking--view--all--card">
          <div className="Group--booking--view--all--left">
            {data.filter(e => e.title === selectedTitle).length === 0 ? (
              <div className="package-not-available">
                <p className="package-not-available-h1">Oops! The Package You're Looking for Isn't Available Right Now.
                </p>
                <p className="package-not-available-p">We’re sorry, but it looks like the package combination you’re interested in is currently unavailable. But don't worry – we’ve got plenty of other fantastic options for you!
                </p>
                <p className="package-not-available-h1">Explore Our Exclusive Packages
                </p>
                <p className="package-not-available-p">While you’re here, why not take a moment to discover some of our other exclusive packages? Plus, you can enjoy amazing deals and discounts that you won’t find anywhere else.
                </p>
              </div>
            ) : (
              <div className="Favorate--hangout--all--thumbnail">
                {data.filter(e => e.title === selectedTitle).map((club, index) => (
                  <Link
                    to={`/${(club.cityName?.replace(/ /g, "-") || '').toLowerCase()}/${(club.areaName?.replace(/ /g, "-") || '').toLowerCase()}/${(club.clubName?.trim().split(/ +/).join("-").replace(/ /g, "-") || '').toLowerCase()}/${(club.title.split("+")[0]?.trim().replace(/ /g, "-") || '').toLowerCase() +
                      (club.title.split("+")[1]?.replace(/ /g, "-") || '').toLowerCase()
                      }`}
                    key={index}
                  >
                    <div className="Favorate--hangout--all--thumbnail--card">
                      <div className="Favorate--hangout--all--thumbnail--card--img--2">
                        {club.packageThumbnail ? (
                          <img
                            className="Favorate--hangout--all--thumbnail--card--img--1--img"
                            src={url + club.packageThumbnail}
                            alt="Club Thumbnail"
                          />
                        ) : (
                          <img
                            className="Favorate--hangout--all--thumbnail--card--img--1--img"
                            src="https://images.unsplash.com/photo-1587497539328-7e140d2ec8d1?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            alt="Default Thumbnail"
                          />
                        )}
                        <div className="group--booking--thumbnail--overlay"></div>
                        <div className="Favorate--hangout--all--thumbnail--card--img--1">
                          <div className="Favorate--hangout--all--thumbnail--card--club--logo">
                            <img src={url + club.clubLogo} alt="Club Logo" />
                          </div>
                        </div>
                        <div className="group--booking--thumbnail--livemusic--card--2"></div>
                      </div>
                      <div className="Favorate--hangout--card--content">
                        <div className="Favorate--hangout--card--content--1">
                          <p className="Favorate--hangout--card--content--1--p1">
                            {club.title.substring(0, 16)}...
                          </p>
                          <p className="Favorate--hangout--card--content--1--p2">
                            {club.clubName.substring(0, 16)}...
                          </p>
                          <p className="Favorate--hangout--card--content--1--direction"></p>
                        </div>
                        <div className="favorate--hangout--address">
                          {club.totalRating !== 0 ? (
                            <div className="favorate--hangout--review--card1">
                              <div className="favorate--hangout--review--card">
                                <img src={star} alt="Star Icon" />
                                <p>{club.totalAverageRating}</p>
                              </div>
                            </div>
                          ) : (
                            <div className="favorate--hangout--review--card5"></div>
                          )}
                          <p className="favorate--hangout--address--p2">{club.distance} km</p>
                          <p className="favorate--hangout--address--p2">
                            {club.address.substring(0, 17)}...
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}


            <p style={{ color: "#fff" }}>
              {!isReadmore
                ? `${Targetting_content.slice(0, 550)}...`
                : Targetting_content}
            </p>
            <p
              onClick={() => setReadmore(!isReadmore)}
              style={{
                textAlign: "center",
                color: "#fd2f71",
                cursor: "pointer",
              }}
            >
              read more
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Carousel } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { LuSearch } from "react-icons/lu";
import { useParams } from "react-router-dom";
import "./Carnival.css";
import { Helmet } from "react-helmet-async";

import CarnivalPassCard from "./CarnivalPassCard";

import Commons from "./../Commons";
import { CarnivalAPI } from "./CarnivalAPIs";

const searchBarStyleMobile = {
  height: "40px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#120829",
  // borderRadius: "20px",
  padding: "10px 10px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.3s ease-in-out",
  color: "white",
  border: "1px solid grey",
};

const inputStyle = {
  border: "none",
  outline: "none",
  fontSize: "12px",
  width: "100%",
  backgroundColor: "#120829",
  color: "white",
};

export default function Carnival() {
  const { type, } = useParams();
  // console.log('Offer Type', offer_type);

  const [passes, setPasses] = useState([]);
  const [specialPasses, setSpecialPasses] = useState([]);
  const [typeInfo, setTypeInfo] = useState({});

  const [showFooterFull, setShowFooterFull] = useState(false);

  // Fetch all Carnival Passes of a Type from Special Clubs
  useEffect(() => {
    /*
    const body = new FormData();
    body.append('type', type);

    // Fetch categories on component mount
    Commons.doFetch('Carnival/CarnivalPassSpecialOffer', body)
      .then((response) => {
        // console.log(type, ' - Special Offers:', response);
        if (response.status === "true") {
          setSpecialPasses([]);
        } else {
          // console.log('Special Carnival Passes: ', response);
          let passes = response.data || [];
          passes = passes.map(p => {
            p = { ...p, ...p.passDetails };
            return p;
          });

          setSpecialPasses(passes);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
      */
  }, []);

  // Fetch all Carnival Pass of a Type
  useEffect(() => {
    Commons.doFetch(`Api/getData/allCarnivalPassOfType?type=${type}`)
      .then((response) => {
        // console.log('All Carnival: ', response);

        if (response.status === "true") {
          setPasses([]);
        } else {
          let passes = response.data || [];
          passes = passes.map(p => {
            p = { ...p, ...p.passDetails };
            return p;
          });
          passes = removeDuplicates(passes, specialPasses);

          setPasses(passes);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  // Get Details of Canival Type
  useEffect(() => {
    Commons.doFetch(`APIs/Carnival/getCarnivalTypes`)
      .then((response) => {
        if (response?.status === true) {
          // console.log('All CarnivalTypes: ', response);
          response.data?.forEach(t => {
            if (t.id === type || t.name === type) {
              setTypeInfo(t);
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching Canival Types Info:", error);
      });
  }, []);

  const removeDuplicates = (passes, specials) => {
    // remove duplicates
    passes.forEach((p, index) => {
      const passID = p.id;
      const found = specials?.filter(sp => {
        return sp.id === passID;
      });
      if (found?.length > 0) {
        passes.splice(index, 1);
      }
    });
    return passes;
  }

  const getMetaFooter = () => {
    let footerText = typeInfo?.meta_footer || '';
    // to perform substitutions
    passes?.forEach((pass, i) => {
      footerText = footerText.replace('{{' + (i + 1) + '}}', `<a href='${CarnivalAPI.getFullViewList(pass)}' alt='${pass.name}'>${pass.name}</a>`);
    });
    // console.log('Footer Text: ', footerText);

    return footerText;
  }

  // banners
  const [banners, setBanners] = useState([]);
  var url = Commons.URL.Upload;

  useEffect(() => {
    axios
      .get("https://partywitty.com/master/Api/getData/getCarnivalBanner")
      .then((response) => setBanners(response.data.data))
      .catch((error) => console.log(error));
  }, []);

  // filtered passes
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredSpecials, setFilteredSpecials] = useState([]);
  const [userInput, setUserInput] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setUserInput(inputValue);

    // Fetch options based on the inputValue
    const filteredData = getOptions(inputValue, passes);
    setFilteredItems(filteredData);

    const filteredSpecials = getOptions(inputValue, specialPasses);
    setFilteredSpecials(filteredSpecials);

    // Update filteredItems based on inputValue
    // applyFilter(inputValue);
  };

  const getOptions = (inputValue, passes) => {
    inputValue = inputValue?.trim()?.toLowerCase();
    if (!inputValue) {
      return []; // Return empty array if input is empty or whitespace
    }

    const filteredData = passes.filter(
      (pass) => {
        let match = pass.name.toLowerCase().includes(inputValue);

        if (!match) {
          match = pass.club?.name.toLowerCase().includes(inputValue);
        }

        if (!match) {
          const PD = pass.passDetails;
          for (let alcType of CarnivalAPI.Alcohols) {
            const alcohols = PD ? PD[alcType] : undefined;
            if (alcohols) {
              match = alcohols.toLowerCase().includes(inputValue);
              if (match) {
                break;
              }
            }
          }
        }


        // console.log('Match Option: ', match, inputValue, option);
        return match;
      }
    );

    return filteredData.length > 0 ? filteredData : []; // Return filteredData if it has items else return empty array
  };

  // contents
  const buildBanner = (header = true) => {
    return (
      <div className="new--year--banner--5" >
        <div className="Artist-viewall-banner">
          <Carousel autoplay>
            {banners.map((e) => (
              <div className="Artist-viewall-banner--img">
                <img src={url + (header ? e.img : e.footer_img)} alt="img" />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    );
  }

  const buildSpecialPasses = () => {
    return (
      <div className="new--year--special--package">
        {userInput.trim() === "" ?
          specialPasses?.map(
            (e) => (// Carnival Special Pass Card
              <CarnivalPassCard pass={e} sponsored={true} />
            )
          )
          : (filteredSpecials?.map(
            (e) => (// Carnival Special Pass Card
              <CarnivalPassCard pass={e} sponsored={true} />
            )
          )
          )
        }
      </div>
    );
  }

  const buildPasses = () => {
    return (
      <div
        className="new--year--special--package"
      >
        {userInput.trim() === "" ? (
          passes.map((e) => ( // Carnival Pass Card
            <CarnivalPassCard pass={e} />
          ))
        ) :
          (
            filteredItems?.map((e) => (// Carnival Pass Card
              <CarnivalPassCard pass={e} />
            ))
          )
        }
      </div>

    );
  }

  const buildSearch = () => {
    return (
      <div style={searchBarStyleMobile}>
        <input
          style={inputStyle}
          type="text"
          placeholder="Search..."
          value={userInput}
          onChange={handleInputChange}
        />
        <span>
          <LuSearch />
        </span>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        {typeInfo?.meta_title && (<title>
          {`${typeInfo?.meta_title}`}
        </title>
        )}
        {typeInfo?.meta_desc && (
          <meta
            name="description"
            content={`${typeInfo?.meta_desc}`}
          />
        )}
      </Helmet>
      <div className="New--year--page--design--1" style={{ position: 'relative', top: '75px', }}>
        <div className="new--year--mobile--view--1">
          {buildBanner()}

          <div>
            {buildSearch()}
            {/* buildSpecialPasses() */}
            {buildPasses()}
          </div>
          <div style={{ color: 'white', padding: '30px' }} dangerouslySetInnerHTML={{ __html: getMetaFooter() }}>
          </div>
          {buildBanner(false)}
        </div>
      </div>
    </>
  );

}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BsShareFill } from "react-icons/bs";
import { FaDirections } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdFavorite } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation } from "react-router-dom";
import "../App.css";
import "../Club-page/Clubpage.css";
import "../Web--to--app/WebApp.css";
import Clubaboutuspagebanneruser from "./ClubaboutusbannerforNormaluser";

export default function ClubHeader(props) {
  const [id, setID] = useState(props.id);
  // console.log('Club Header for :', id);

  const [clubDetails, setClubDetails] = useState([]);

  const loadClubDetails = () => {
    // console.log('Get Club Details for :', id);

    const newApi = new FormData();
    newApi.append("id", id);

    axios
      .post("https://partywitty.com/master/APIs/Web/getClubDetailsByID", newApi)
      .then((response) => {
        setClubDetails(response.data.data);
        // setClubId(response.data.data.id);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  if (!clubDetails || clubDetails?.length === 0) {
    loadClubDetails();
  } else {
    // console.log('Club Details: ', clubDetails);
  }

  return (
    <>
      <div className="club-aboutus-img-caraousel">
        <Clubaboutuspagebanneruser details={clubDetails} key={Math.random()} />
        <div class="location mt-3">
          <div class="location-part">
            <div className="clubname--dirction--icon">
              <h2 class="h2 m-0">{clubDetails.name} </h2>
            </div>
            <div className="details-text">
              <span>
                <HiOutlineLocationMarker />
              </span>
              <p> {clubDetails.address}</p>
            </div>
            <div className="details-text">
              <span>
                <AiOutlineFieldTime />
              </span>{" "}
              <p> Open from {clubDetails.open_time}</p>
              <br />
              <p> Close from {clubDetails.close_time}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

